import './CustomPieChart.scss';
import { legendClasses, PieChart } from '@mui/x-charts';

type PieChartSeries = {
  type: string;
  typeNumber: number;
  total: number;
};
type CustomPieChartProps = {
  name: string;
  data: PieChartSeries[];
  handleItemClick: React.Dispatch<React.SetStateAction<string>>;
};

function CustomPieChart({ name, data, handleItemClick }: CustomPieChartProps) {
  const total = data.reduce(
    (acc: number, current: { typeNumber: number }) => acc + current.typeNumber,
    0
  );

  return (
    <div className="chart">
      <div className="chart__name">{name}</div>
      <div className="chart__body">
        <PieChart
          sx={{
            '& .MuiPieArc-root': {
              transition:
                'opacity 0.3s ease-in, fill 0.3s ease-in, filter 0.3s ease-in',
            },
            [`& .${legendClasses.mark}`]: {
              ry: 10,
            },
          }}
          width={300}
          height={320}
          skipAnimation={false}
          colors={['#9ad04b', '#eb4765', '#52bbf8']}
          onItemClick={(_, __, item) => handleItemClick(item.label as string)}
          series={[
            {
              arcLabel: 'value',
              arcLabelMinAngle: 35,
              valueFormatter: (item) => `${item.value}/${total}`,
              data: data.map((item: PieChartSeries) => ({
                value: item.typeNumber,
                label: item.type,
              })),
              highlightScope: { fade: 'global', highlight: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
              cy: 110,
              cx: 150,
              startAngle: -45,
            },
          ]}
          slotProps={{
            pieArcLabel: {
              style: {
                fontWeight: 400,
                fontSize: 12,
                fill: 'white',
              },
            },
            legend: {
              position: { vertical: 'bottom', horizontal: 'left' },
              padding: 16,
              labelStyle: {
                fontSize: 14,
                fontFamily: 'Helvetica Neue',
                fill: '#10384F',
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default CustomPieChart;
