import './Dashboard.scss';
import { Card } from 'components/Card/Card';
import { Link } from 'react-router-dom';
import { useReportsData } from '../../fetch/fetch-hooks/country-dashboard-hooks/useReports';
import { useContext, useState } from 'react';
import { getFromLocalStorage } from '../../services/storageInterection';
import CustomPieChart from 'components/Charts/CustomPieChart';
import CircularProgress from '@mui/material/CircularProgress';
import { UserDataContext } from '../../App';
import DashboardDistributorsTable from './DashboardDistributorsTable';
import DashboardReportsTable from './DashboardReportsTable';
import {
  fullyReportedDistr,
  fullyNotReportedDistr,
  partiallyReportedDistr,
} from 'utils/dashboardUtils';
import useEnabledReports, {
  EnabledReportsType,
} from 'fetch/fetch-hooks/useEnabledReports';
// import useRejectionReasons from 'fetch/fetch-hooks/country-dashboard-hooks/useRejectionReasons';
// import RejectionReasonsPieChart from 'components/Charts/RejectionReasonsPieChart/RejectionReasonsPieChart';
// import { Box } from '@mui/material';
// import ReportSubmissionAttempts from 'components/dashboard_2.0/ReportSubmissionAttempts/ReportSubmissionAttempts';
// import CustomToggleButtonGroup from 'components/CustomToggleButtonGroup/CustomToggleButtonGroup';

interface FileDetailsWithStatus {
  distributor_id: number;
  Distributor_Name: string;
  status: string;
  widget_status: string;
  filename: string;
  country: string;
  reporting_period: string;
}

interface FileDetailsWithStatusArray {
  distributor_id: number;
  Distributor_Name: string;
  status: string[];
  widget_status: string[];
  filename: string[];
  country: string;
}

// type RejectionReason = {
//   rejection_reason: string;
//   count_of_rejection_reason: number;
//   previous_accepted_period: string;
// };

function Dashboard() {
  const [distributorsFilter, setDistributorsFilter] = useState('all');
  const [reportsFilter, setReportsFilter] = useState('all');
  const selectedCountry = getFromLocalStorage('selectedCountry');
  const userData = useContext(UserDataContext);
  const { userProfile, authResult } = userData;
  // const [rejectionReasonsPeriod, setRejectionReasonsPeriod] =
  //   useState('currentPeriod');

  // const {
  //   data: rejectionReasonsResponseData,
  //   isLoading: isRejectionReasonsLoading,
  // } = useRejectionReasons(authResult, selectedCountry, rejectionReasonsPeriod);

  // const rejectionReasonsData = rejectionReasonsResponseData?.data || [];

  const {
    data: enabledReports,
  }: { data: EnabledReportsType; isLoading: boolean } = useEnabledReports(
    authResult,
    selectedCountry
  );

  const showPartiallyReported =
    (enabledReports?.inventory && enabledReports?.sellout) || false;

  const { data: allReportsResponseData, isLoading: isAllReportsDataLoading } =
    useReportsData(
      [
        'GET',
        `${process.env.REACT_APP_API_PYTHON_API}/get_file_details_with_status`,
        { country: selectedCountry, widget_status: 'all' },
      ],
      authResult
    );

  const {
    data: allDistributorsResponseData,
    isLoading: isAllDistributorDataLoading,
  } = useReportsData(
    [
      'GET',
      `${process.env.REACT_APP_API_PYTHON_API}/get_file_details_with_status_array`,
      { country: selectedCountry, chart_status: 'all' },
    ],
    authResult
  );

  const {
    data: filteredReportsDataResponse,
    isLoading: isFilteredReportsLoading,
  } = useReportsData(
    [
      'GET',
      `${process.env.REACT_APP_API_PYTHON_API}/get_file_details_with_status`,
      { country: selectedCountry, widget_status: reportsFilter.toLowerCase() },
    ],
    authResult
  );

  const {
    data: filteredDistributorDataResponse,
    isLoading: isFilteredDistributorsLoading,
  } = useReportsData(
    [
      'GET',
      `${process.env.REACT_APP_API_PYTHON_API}/get_file_details_with_status_array`,
      {
        country: selectedCountry,
        chart_status: distributorsFilter.toLowerCase(),
      },
    ],
    authResult
  );

  const allReportsData = allReportsResponseData?.data || [];
  const allDistributorsData = allDistributorsResponseData?.data || [];
  const filteredDistributorData = filteredDistributorDataResponse?.data || [];
  const filteredReportsData = filteredReportsDataResponse?.data || [];

  function handleDistributorsDataForChart(
    distributorsData: FileDetailsWithStatusArray[],
    showPartiallyReported: boolean
  ) {
    const chartData = [
      {
        type: 'Reported',
        typeNumber: fullyReportedDistr(distributorsData),
        total: distributorsData.length,
      },
      {
        type: 'Missing',
        typeNumber: fullyNotReportedDistr(distributorsData),
        total: distributorsData.length,
      },
    ];

    if (showPartiallyReported) {
      chartData.push({
        type: 'Partially Reported',
        typeNumber: partiallyReportedDistr(distributorsData),
        total: distributorsData.length,
      });
    }

    return chartData;
  }

  function handleReportsDataForChart(reportData: FileDetailsWithStatus[]) {
    return [
      {
        type: 'Received',
        typeNumber: reportData.filter(
          (obj: FileDetailsWithStatus) =>
            obj.status !== 'MISSING' && obj.status !== 'REWORK'
        ).length,
        total: reportData?.length,
      },
      {
        type: 'Missing',
        typeNumber: reportData.filter(
          (obj: FileDetailsWithStatus) =>
            obj.status === 'MISSING' || obj.status === 'REWORK'
        ).length,
        total: reportData?.length,
      },
    ];
  }

  const exceptionsFoundNumber = allReportsData.filter(
    (obj: any) => obj.status === 'REVIEW'
  ).length;

  const mappedSuccessfullyNumber = allReportsData.filter(
    (obj: any) => obj.status === 'SUCCESS'
  ).length;

  // function handleRejectionReasonsDataForChart(
  //   rejectionReasonsData: RejectionReason[]
  // ) {
  //   const totalCount = rejectionReasonsData.reduce(
  //     (acc: number, data: { count_of_rejection_reason: number }) =>
  //       acc + data.count_of_rejection_reason,
  //     0
  //   );

  //   const topThreeReasonsData = rejectionReasonsData
  //     .slice(0, 3)
  //     .map((data: RejectionReason) => ({
  //       type: data.rejection_reason,
  //       typeNumber: data.count_of_rejection_reason,
  //       percentage: +(
  //         (data.count_of_rejection_reason / totalCount) *
  //         100
  //       ).toFixed(1),
  //     }));

  //   const othersCount =
  //     totalCount -
  //     topThreeReasonsData.reduce(
  //       (acc: number, data: { typeNumber: number }) => acc + data.typeNumber,
  //       0
  //     );
  //   const othersPercentage = ((othersCount / totalCount) * 100).toFixed(1);

  //   const othersData = {
  //     type: 'Other',
  //     typeNumber: othersCount,
  //     percentage: +othersPercentage,
  //   };

  //   return [...topThreeReasonsData, othersData];
  // }

  // function handleRejectionReasonsDataForList(
  //   rejectionReasonsData: RejectionReason[]
  // ) {
  //   const totalCount = rejectionReasonsData.reduce(
  //     (acc: number, data: { count_of_rejection_reason: number }) =>
  //       acc + data.count_of_rejection_reason,
  //     0
  //   );

  //   const percentageData = rejectionReasonsData.map(
  //     (data: RejectionReason) => ({
  //       type: data.rejection_reason,
  //       typeNumber: data.count_of_rejection_reason,
  //       percentage: (
  //         (data.count_of_rejection_reason / totalCount) *
  //         100
  //       ).toFixed(1),
  //     })
  //   );

  //   return percentageData;
  // }

  // const transformedDataForChartRejectionReasons =
  //   handleRejectionReasonsDataForChart(rejectionReasonsData);

  // const transformedDataForListRejectionReasons =
  //   handleRejectionReasonsDataForList(rejectionReasonsData);

  return (
    <>
      {!userProfile?.isEMEA &&
        (isAllReportsDataLoading || isAllDistributorDataLoading) && (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: '45%',
              left: '45%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
      {allReportsData?.length > 0 &&
        allDistributorsData?.length > 0 &&
        !userProfile?.isEMEA && (
          <>
            <div className="inform-cards">
              <div className="inform-cards__inform-card">
                <Card
                  name="Reports"
                  bodyInfo={`${
                    allReportsData?.filter(
                      (obj: FileDetailsWithStatus) =>
                        obj.status !== 'MISSING' && obj.status !== 'REWORK'
                    ).length
                  }/${allReportsData?.length}`}
                  bodyExplaining={'received/total'}
                  status={''}
                  update={''}
                >
                  <Link to={'/reports'} relative="path">
                    See all
                  </Link>
                </Card>
              </div>
              <div className="inform-cards__inform-card">
                <Card
                  name="Exceptions Found"
                  bodyInfo={`${exceptionsFoundNumber}`}
                  bodyExplaining={'reports to review'}
                  status={''}
                  isDangerStatus
                >
                  {exceptionsFoundNumber !== 0 && (
                    <Link to={'/reports?status=REVIEW&reporting_period=last'}>
                      See all
                    </Link>
                  )}
                </Card>
              </div>
              <div className="inform-cards__inform-card">
                <Card
                  name="Mapped Successfully"
                  bodyInfo={`${mappedSuccessfullyNumber}`}
                  bodyExplaining={''}
                  status={''}
                >
                  {mappedSuccessfullyNumber !== 0 && (
                    <Link to={'/reports?status=SUCCESS&reporting_period=last'}>
                      See all
                    </Link>
                  )}
                </Card>
              </div>
            </div>

            <div className="panel">
              <div className="panel__chart">
                <CustomPieChart
                  name="Distributors"
                  data={handleDistributorsDataForChart(
                    allDistributorsData,
                    showPartiallyReported
                  )}
                  handleItemClick={setDistributorsFilter}
                />
              </div>
              <div className="panel__table">
                <DashboardDistributorsTable
                  data={filteredDistributorData}
                  country={selectedCountry}
                  isLoading={isFilteredDistributorsLoading}
                />
              </div>
            </div>
            <div className="panel">
              <div className="panel__chart">
                <CustomPieChart
                  name="Reports"
                  data={handleReportsDataForChart(allReportsData)}
                  handleItemClick={setReportsFilter}
                />
              </div>
              <div className="panel__table">
                <DashboardReportsTable
                  data={filteredReportsData}
                  country={selectedCountry}
                  isLoading={isFilteredReportsLoading}
                />
              </div>
            </div>
            {/* <Box className="flexible-charts-container">
              <Box className="rejection-reasons-charts">
                <Box className="list-chart-container">
                  <div className="chart__name">Report Rejection Reasons</div>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CustomToggleButtonGroup
                      value={rejectionReasonsPeriod}
                      options={{
                        currentPeriod: 'Current Period',
                        previousPeriod: 'Previous Period',
                      }}
                      onChange={setRejectionReasonsPeriod}
                      disabled={isRejectionReasonsLoading}
                    />
                  </Box>
                  {isRejectionReasonsLoading ? (
                    <CircularProgress
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    />
                  ) : (
                    <ul className="listChart">
                      {transformedDataForListRejectionReasons.map((obj) => {
                        const percentageString = `${obj.percentage}%`;
                        return (
                          <li key={obj.type}>
                            <span>{obj.type}</span>
                            <div className="dotted"></div>
                            <span>
                              {percentageString.padStart(8, '\u00A0')}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </Box>
                <Box className="rejection-pie-container">
                  <RejectionReasonsPieChart
                    name="Report Rejection Reasons - Top 3"
                    data={transformedDataForChartRejectionReasons}
                    handleItemClick={setReportsFilter}
                    loading={isRejectionReasonsLoading}
                  />
                </Box>
              </Box>
              <Box className="submission-attempts-container">
                <ReportSubmissionAttempts
                  country={selectedCountry}
                  isCountryDashboard={true}
                />
              </Box>
            </Box> */}
          </>
        )}
    </>
  );
}

export default Dashboard;
