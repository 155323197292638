import useSWR from 'swr';

const fetchColumnsData = async ([authResult, method, url, data, reportType]: any) => {
  if (!authResult?.accessToken) throw new Error('Missing authentication token');

  const headers = new Headers();
  const bearer = `${authResult?.accessToken}`;
  headers.append('Authorization', bearer);

  if (method === 'GET' && data) {
    url += '?' + new URLSearchParams(data);
  }

  let options: any = {
    method: method,
    headers: headers,
  };

  if (method !== 'GET' && data) {
    options.body = JSON.stringify(data);
    headers.append('Content-Type', 'application/json');
  }

  try {
    const response = await fetch(url, options);
    
    if (!response.ok) {
      // Handle different status codes
      const errorText = await response.text(); 
      throw new Error(`API error: ${response.status} ${response.statusText} - ${errorText}`);
    }

    const responseJson = await response.json();
    return responseJson[reportType];
  } catch (error: any) {
    console.error('Fetch error:', error.message);
    throw error; // Important! SWR will catch this error.
  }
};

function useFetchColumnsData(country: string | null, authResult: any, reportType: any) {
  return useSWR(
    [
      authResult,
      'GET',
      `${process.env.REACT_APP_API_PYTHON_API}/get_templates_by_country?country=${country}`,
      null,
      reportType
    ],
    fetchColumnsData
  );
}

export default useFetchColumnsData;
