import {
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import PreviewIcon from 'icons/preview/previewIcon.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Toolbar from 'customized-mui-elements/Toolbar/Toolbar';
import { Typography } from '@mui/material';
import { CustomTooltipWrapper } from 'components/CustomTooltipWrapper/CustomTooltipWrapper';
import TemplatesTableModalContent from 'components/TemplatesTableModalContent/TemplatesTableModalContent';
import TemplatesCustomExportButton from 'components/TemplatesCustomExportButton/TemplatesCustomExportButton';

interface ReportTemplateTableToolbarProps {
  onAddNewRow: () => void;
  onPreview: () => void;
  isDisabledMode: boolean;
  isOnboardingReportTemplate: boolean;
  handleDownloadClick: () => void;
}

function ReportTemplateTableToolbar({
  onAddNewRow,
  onPreview,
  isDisabledMode,
  isOnboardingReportTemplate,
  handleDownloadClick,
}: ReportTemplateTableToolbarProps) {
  return (
    <Toolbar>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: 'auto',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <div>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <TemplatesCustomExportButton
            handleDownloadClick={handleDownloadClick}
          />
          <Button
            onClick={onPreview}
            startIcon={<img src={PreviewIcon} alt={'preview-icon'} />}
          >
            Preview
          </Button>
        </div>
        <div className="toolbar-preview-section">
          <div className="toolbar-preview-section__naming-convention naming-convention">
            <CustomTooltipWrapper
              title={<TemplatesTableModalContent />}
              width="200px"
              arrow
            >
              <InfoOutlinedIcon />
            </CustomTooltipWrapper>
            <Typography
              sx={{
                marginLeft: '8px',
                color: '#10384F',
                fontFamily: 'Helvetica Neue',
                fontWeight: 400,
                textTransform: 'none',
              }}
            >
              File Naming Convention
            </Typography>
          </div>
          <Button
            variant={isOnboardingReportTemplate ? 'outlined' : 'contained'}
            onClick={onAddNewRow}
            disabled={isDisabledMode}
            sx={{
              marginRight: '20px',
              fontFamily: 'Helvetica Neue',
              ...(isOnboardingReportTemplate && {
                borderColor: 'rgba(0, 0, 0, 0.42)',
                color: '#10384F',
              }),
            }}
          >
            ADD REPORT FIELD
          </Button>
        </div>
      </div>
    </Toolbar>
  );
}

export default ReportTemplateTableToolbar;
