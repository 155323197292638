import CountryReportingPerformanceChart, {
  CountryReportingPerformanceStats,
} from './CountryReportingPerformanceChart';
import { useContext, useEffect, useState } from 'react';
import { countryPerformanceChartLegendConfig } from './chartConfigs';
import ChartContainer from '../shared/ChartContainer';
import ChartDetailsContainer from '../shared/ChartDetailsContainer';
import CustomLegend from '../shared/CustomLegend';
import { Box } from '@mui/material';
// import DownloadButton from "../shared/DownloadButton";
import { ReportType } from '../ReportingPerformance/types';
import useCountryReportingPerformance from 'fetch/fetch-hooks/emea-dashboard-hooks/useCountryReportingPerformance';
import { filterAndSortData } from './chartUtils';
import { UserDataContext } from 'App';
import CustomToggleButtonGroup from 'components/CustomToggleButtonGroup/CustomToggleButtonGroup';

interface ReportData {
  InventoryReport: CountryReportingPerformanceStats[];
  SelloutReport: CountryReportingPerformanceStats[];
}

function CountryReportingPerformance() {
  const [reportType, setReportType] = useState<ReportType>('InventoryReport');
  const [reportData, setReportData] = useState<ReportData>({
    InventoryReport: [],
    SelloutReport: [],
  });
  const { authResult } = useContext(UserDataContext);
  const { data: countryReportingPerformance } =
    useCountryReportingPerformance(authResult);

  useEffect(() => {
    if (countryReportingPerformance?.data) {
      setReportData({
        InventoryReport: filterAndSortData(
          countryReportingPerformance.data,
          'InventoryReport'
        ),
        SelloutReport: filterAndSortData(
          countryReportingPerformance.data,
          'SelloutReport'
        ),
      });
    }
  }, [countryReportingPerformance]);

  const stats = reportData[reportType];

  return (
    <ChartContainer title="Country reporting performance">
      <ChartDetailsContainer>
        <CustomLegend legendConfig={countryPerformanceChartLegendConfig} />
        <Box display={'flex'} alignItems={'center'}>
          <CustomToggleButtonGroup<ReportType>
            value={reportType}
            onChange={setReportType}
            options={{
              InventoryReport: 'Inventory',
              SelloutReport: 'Sell-out',
            }}
          />

          {/* <DownloadButton /> */}
        </Box>
      </ChartDetailsContainer>
      <CountryReportingPerformanceChart stats={stats} />
    </ChartContainer>
  );
}

export default CountryReportingPerformance;
