import ChartContainer from '../shared/ChartContainer';
import CustomLegend from '../shared/CustomLegend';
import ReportSubmissionAttempsChart from './ReportSubmissionAttemptsChart';
import useReportSubmissionAttempts from 'fetch/fetch-hooks/emea-dashboard-hooks/useReportSubmissionAttempts';
import { formatBarChartData } from './chartUtils';
import { useContext } from 'react';
import { UserDataContext } from 'App';

export const chartColors = {
  inventory: 'var(--blue)',
  sellout: 'var(--bright-blue)',
};

export const reportSubmissionAttemptsLegendConfig = [
  {
    color: 'var(--red)',
    label: 'No successful report submit',
    shape: 'circle',
  },
];

function ReportSubmissionAttempts({
  country,
  isCountryDashboard = false,
}: {
  country: string;
  isCountryDashboard?: boolean;
}) {
  const { authResult } = useContext(UserDataContext);
  const { data: reportSubmissionAttempts } = useReportSubmissionAttempts(
    country,
    authResult
  );
  const data = reportSubmissionAttempts?.data || [];
  const dataset = formatBarChartData(data);
  const titleStyle = isCountryDashboard
    ? { fontSize: '22px', fontWeight: 500 }
    : undefined;

  const customStyles = isCountryDashboard
    ? {
        boxShadow:
          '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
        borderRadius: '10px',
      }
    : {};
  return (
    <ChartContainer
      customStyles={customStyles}
      title="Report submission attempts per 1 success"
      titleStyle={titleStyle}
    >
      <CustomLegend legendConfig={reportSubmissionAttemptsLegendConfig} />
      <ReportSubmissionAttempsChart dataset={dataset} />
    </ChartContainer>
  );
}

export default ReportSubmissionAttempts;
