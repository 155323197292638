import React from 'react';
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
  CircularProgress,
} from '@mui/material';
import { ReportStatus } from '../../customized-mui-elements/Statuses/Statuses';
import getBaseUrl from 'utils/getBaseUrl';
import { isSuccessfulStatus } from 'utils/isSuccessfulStatus';
import { generateLink } from 'utils/generateLink';
import { Link } from 'react-router-dom';

export interface Row {
  distributor_id: number;
  Distributor_Name: string;
  status: string;
  widget_status: string;
  filename: string;
}

interface MyTableProps {
  data: Row[];
  country: string;
  isLoading: boolean;
}

const DashboardReportsTable: React.FC<MyTableProps> = ({
  data,
  country,
  isLoading,
}) => {
  const fullUrl = window.location.href;
  const baseUrl = getBaseUrl(fullUrl) ?? '';

  const columnHeaderStyles = {
    fontFamily: 'inherit',
    color: 'inherit',
    fontSize: '11px',
    fontWeight: 700,
    padding: '12px',

    lineHeight: '16px',
    letterSpacing: '0.04em',
    textTransform: 'uppercase',
  };

  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: 360, overflowY: 'auto', height: '100%' }}
    >
      <Table
        stickyHeader
        sx={{
          height: '100%',
          fontFamily: 'Helvetica Neue',
          color: '#10384f',
          '& .MuiTableCell-root': {
            verticalAlign: 'top',
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell sx={columnHeaderStyles}>Distributor</TableCell>
            <TableCell sx={columnHeaderStyles}>Report name</TableCell>
            <TableCell sx={columnHeaderStyles}>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ position: 'relative', height: '100%' }}>
          {isLoading ? (
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            data.map((report, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Link
                    to={`/distributor?name=${report?.Distributor_Name}&distributor_id=${report?.distributor_id}`}
                  >
                    <span className="distributor-name">
                      {report?.Distributor_Name}
                    </span>
                    <br />
                    <span className="distributor-id">
                      {report?.distributor_id}
                    </span>
                  </Link>
                </TableCell>

                <TableCell
                  sx={{
                    fontFamily: 'Helvetica Neue',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    textAlign: 'left',
                    color: '#10384f',
                  }}
                >
                  {isSuccessfulStatus(report.status) ? (
                    <Link
                      to={generateLink(
                        baseUrl,
                        report.filename,
                        report.distributor_id,
                        country
                      )}
                    >
                      {report.filename}
                    </Link>
                  ) : (
                    report.filename
                  )}
                </TableCell>
                <TableCell>
                  {isSuccessfulStatus(report.status) ? (
                    <Link
                      to={generateLink(
                        baseUrl,
                        report.filename,
                        report.distributor_id,
                        country
                      )}
                    >
                      <ReportStatus label={report?.status} small />
                    </Link>
                  ) : (
                    <ReportStatus label={report?.status} small />
                  )}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardReportsTable;
