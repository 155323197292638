import React, { useState, useEffect, useContext } from 'react';
import './ReportDetailsPage.scss';
import { PageInfoContext } from 'contexts/PageInfoContext';
import { getFromLocalStorage } from 'services/storageInterection';
import ReportDetails from 'components/ReportDetails/ReportDetails';
import CircularProgress from '@mui/material/CircularProgress';
import { useSearchParams } from 'react-router-dom';
import useFetchReportsData from 'fetch/fetch-hooks/reports-hooks/useFetchReportsData';
import useFetchReportContent from 'fetch/fetch-hooks/reports-hooks/useFetchReportContent';
import { fetchDataForMappingChoice } from 'fetch/fetch-requests/reportsRequests';
import { AlertsContext } from 'contexts/AlertsContext';
import getInteger from 'utils/getInteger';
import { useNavigate } from 'react-router-dom';
import { UserDataContext } from 'App';
import {
  sendRequest,
  FetchParams,
  handleApiRequest,
} from 'fetch/fetch-requests/handleApiRequest';
import { getUnselectedAlternatives } from 'components/ReportDetails/services';
import useFetchColumnsData from 'fetch/fetch-hooks/reports-hooks/useFetchColumnsData';
import {
  ReportStatusType,
  ApprovePayloadObject,
  ReportDataObjType,
  UnselectedAlternativesType,
  RememberChoiseType,
  ProductDuplicateType,
} from 'types/reportDetailsTypes';
import ConfirmationSnackbar from 'components/ConfirmationSnackbar/ConfirmationSnackbar';
import DeleteItemDialog from 'components/DeleteItemDialog/DeleteItemDialog';

function extractReportType(filename: string | null) {
  if (filename && filename.includes('Sellout')) {
    return 'Sellout';
  } else if (filename && filename.includes('Inventory')) {
    return 'Inventory';
  } else {
    return null;
  }
}

const ReportDetailsPage: React.FC<any> = (): JSX.Element => {
  const navigate = useNavigate();
  const selectedCountry = getFromLocalStorage('selectedCountry');
  const { setPageInfo } = useContext(PageInfoContext);
  const { authResult } = useContext(UserDataContext);
  const [searchParams] = useSearchParams();
  const filename = searchParams.get('name');
  const distributor = searchParams.get('distributor');
  const reportType = extractReportType(filename);
  const country = searchParams.get('country') || '';
  const { data: reportsData, isLoading: isLoadingReportsData } =
    useFetchReportsData(country, authResult);
  const [fileStatus, setFileStatus] = useState<ReportStatusType>();

  const {
    data: columnsForTableData,
    isLoading: isColumnsForTableLoaded,
    error: errorColumnsForTableLoaded,
  } = useFetchColumnsData(country, authResult, reportType);

  const {
    data: reportContent,
    isLoading: isReportContentLoading,
    mutate: mutateReportContent,
  } = useFetchReportContent(filename, fileStatus, selectedCountry, authResult);
  const [isApproveReportLoaded, setApproveReportLoaded] =
    React.useState<boolean>(false);
  const { setNewAlert } = useContext(AlertsContext);
  const [temporaryData, setTemporaryData] = useState<any>();
  const [unselectedAlternatives, setUnselectedAlternatives] =
    useState<UnselectedAlternativesType | null>(null);
  const [duplicate, setDuplicate] = useState<ProductDuplicateType | null>(null);
  const [rememberChoise, setRememberChoise] =
    useState<RememberChoiseType | null>(null);
  const [deleteRowIndex, setDeleteRowIndex] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const getStatusUpdate = (element: any) => {
    const { matched, alternatives, smart_search } = element;
    if (
      matched &&
      alternatives?.length > 0 &&
      element.statusUpdate !== 'loading'
    ) {
      return 'success';
    }
    if (
      alternatives?.length === 0 &&
      smart_search?.length > 0 &&
      getInteger(matched) === getInteger(smart_search[0].material_number)
    ) {
      return 'success';
    }
    return null;
  };

  const handleSendRequest = async (actionType = 'approve') => {
    setApproveReportLoaded(true);

    const actionUrlPart =
      actionType === 'approve' ? 'move_file_back_to_flow' : 'reject_file';
    const url = `${process.env.REACT_APP_API_PYTHON_API}/${actionUrlPart}?filename=${filename}.csv`;

    const fetchParams = {
      authResult: authResult,
      method: 'POST' as const,
      url: url,
      data: {},
    };

    const onFailure = () => {
      setNewAlert({
        alertType: 'error',
        text: `There is an issue with the attempt to ${actionType} ${filename}`,
      });
      setApproveReportLoaded(false);
    };

    const onSuccess = () => {
      setNewAlert({
        alertType: 'success',
        text: `${filename} was successfully ${actionType === 'approve' ? 'approved' : 'rejected'}`,
      });
      setApproveReportLoaded(false);

      if (actionType === 'approve') {
        setTimeout(() => navigate('/reports'), 3500);
      }
    };

    sendRequest(fetchParams, setApproveReportLoaded, onFailure, onSuccess);
  };

  useEffect(() => {
    if (filename && distributor) {
      setPageInfo({
        headerContent: `${filename} (${distributor})`,
        selectedPage: 'report',
        selectedTab: 'reports',
      });
    }
  }, [filename, distributor]);

  useEffect(() => {
    if (reportsData?.data) {
      const { status }: ReportDataObjType = reportsData?.data.find(
        (reportData: ReportDataObjType) =>
          reportData?.filename === filename &&
          reportData?.distributor_id === Number(distributor)
      );
      setFileStatus(status);
    }
  }, [reportsData]);

  useEffect(() => {
    if (!reportContent) {
      setTemporaryData([]);
      return;
    }

    if (reportContent) {
      const reportTemporaryData = reportContent.map((element: any) => ({
        ...element,
        statusUpdate: getStatusUpdate(element),
      }));
      setTemporaryData(reportTemporaryData);
      setUnselectedAlternatives(() => {
        if (fileStatus === 'REVIEW') {
          return getUnselectedAlternatives(reportTemporaryData);
        } else {
          return reportTemporaryData;
        }
      });
    } else setTemporaryData([]);
  }, [reportContent]);

  function handleUpdateTemporaryData(
    data: any,
    statusUpdate: 'success' | 'error' | 'loading',
    isSmartSearchUpdate: boolean | undefined
  ) {
    const result: any = [];

    temporaryData.forEach((obj: any) => {
      if (obj.id === data.id) {
        if (statusUpdate === 'success') {
          obj.matched = data?.matched_material_id;
          obj.product_name = data?.product_name;
          obj.statusUpdate = 'success';
          if (isSmartSearchUpdate) {
            obj.smart_search = [
              {
                material_name: data?.name,
                material_number: data.matched_material_id,
              },
            ];
            obj.matched = data.matched_material_id;
          }
        }
        if (statusUpdate === 'error') {
          obj.statusUpdate = 'error';
        }
        if (statusUpdate === 'loading') {
          obj.statusUpdate = 'loading';
        }
      }
      result.push(obj);
    });

    setTemporaryData(result);
    // remove in fututre
    if (statusUpdate === 'success') {
      setUnselectedAlternatives(() => {
        return getUnselectedAlternatives(result);
      });
    }
  }

  function handleDuplicatesApprove() {
    if (duplicate?.unicKey && unselectedAlternatives) {
      const duplicates = unselectedAlternatives[duplicate.unicKey].duplicates;
      if (duplicates) {
        const formatedData = duplicates.map((product: any) => {
          return {
            value: duplicate?.matchedMaterialNumber,
            name: duplicate?.matchedMaterialName,
            params: { ...product },
          };
        });

        handleMultiAlternativeChoose(formatedData);
      }
    }

    setDuplicate(null);
  }

  async function handleAlternativeChoose(
    result: ApprovePayloadObject,
    fromSmartSearch: boolean
  ) {
    const data: any = {
      id: result.params.id,
      matched_material_id: result?.value,
      country: country,
      received_product_name: result?.params?.initial_product_data?.product_name
        ? result?.params?.initial_product_data?.product_name
        : '',
      from_smart_search: +fromSmartSearch,
      received_uom: result?.params.received_UOM
        ? result?.params.received_UOM
        : result?.params.uom,
      received_material_id: result?.params?.initial_product_data
        ?.material_number
        ? `${result?.params?.initial_product_data?.material_number}`
        : '',
      received_ean: !!result.params?.EAN ? `${result.params?.EAN}` : '',
      matched_product_name: result.name,
    };

    const requestBody: any = {
      filename: `${filename}.csv`,
      data: [data],
    };

    handleUpdateTemporaryData(data, 'loading', fromSmartSearch);
    const responce = await fetchDataForMappingChoice(requestBody);
    if (responce?.ok) {
      fromSmartSearch
        ? handleUpdateTemporaryData(
            {
              ...data,
              name: result.name,
            },
            'success',
            fromSmartSearch
          )
        : handleUpdateTemporaryData(data, 'success', fromSmartSearch);
      mutateReportContent();
    } else {
      handleUpdateTemporaryData(data, 'error', fromSmartSearch);
    }
  }

  async function handleMultiAlternativeChoose(
    result: Array<ApprovePayloadObject>
  ) {
    const resultArr = result.map((obj: any) => {
      return {
        id: obj.params.id,
        matched_material_id: obj?.value,
        country: country,
        received_product_name: obj?.params?.initial_product_data?.product_name
          ? obj?.params?.initial_product_data?.product_name
          : '',
        from_smart_search: 0,
        received_uom: obj?.params?.received_UOM
          ? obj?.params?.received_UOM
          : obj?.params?.uom,
        received_material_id: obj?.params?.initial_product_data?.material_number
          ? `${obj?.params?.initial_product_data?.material_number}`
          : '',
        received_ean: !!obj.params?.EAN ? `${obj.params?.EAN}` : '',
        matched_product_name: obj.name,
      };
    });

    const requestBody: any = {
      filename: `${filename}.csv`,
      data: resultArr,
    };

    resultArr.forEach((obj: any) => {
      handleUpdateTemporaryData(obj, 'loading', false);
    });
    const responce = await fetchDataForMappingChoice(requestBody);
    if (responce?.ok) {
      resultArr.forEach((obj: any) => {
        handleUpdateTemporaryData(obj, 'success', false);
      });
      mutateReportContent();
    } else {
      resultArr.forEach((obj: any) => {
        handleUpdateTemporaryData(obj, 'error', false);
      });
    }
  }

  async function handleRememberChoise() {
    const params: any = {
      country: country || '',
      matched_material_id: rememberChoise?.matchedMaterialNumber || '',
      received_product_name:
        rememberChoise?.params?.initial_product_data?.product_name || '',
      distributor_id: distributor || '',
      received_material_number:
        rememberChoise?.params?.initial_product_data?.material_number || '',
      received_ean: rememberChoise?.params?.EAN || '',
      received_uom: rememberChoise?.params?.received_UOM || '',
    };

    const fetchParams: FetchParams = {
      authResult,
      method: 'GET',
      url: `${process.env.REACT_APP_API_PYTHON_API}/remember_the_choice`,
      data: params
    };


    const errorMessage = 'Something went wrong, please try again';
    const successMessage = 'it was remembred';

    const onFailure = () => {
      setNewAlert({
        alertType: 'error',
        text: errorMessage,
      });
    };

    const onSuccess = () => {
      setNewAlert({
        alertType: 'success',
        text: successMessage,
      });

      setRememberChoise(null);
    };

    await sendRequest(fetchParams, () => {}, onFailure, onSuccess);
  }

  async function handleApproveReport() {
    handleSendRequest('approve');
  }

  async function handleRejectReport() {
    handleSendRequest('reject');
  }

  function confirmDeleteRow() {
    if (deleteRowIndex !== null) {
      handleDeleteRow(deleteRowIndex);
    }
    setDeleteRowIndex(null);
  }

  async function handleDeleteRow(rowNumber: number) {
    const filenameWithExtension = `${filename}.csv`;
    const url = `delete_row_from_exception_file?filename=${filenameWithExtension}&row_number=${rowNumber}`;
    const fetchParams: FetchParams = {
      authResult,
      method: 'POST',
      url: `${process.env.REACT_APP_API_PYTHON_API}/${url}`,
    };
    const successMessage = 'Row was deleted successfully';
    const errorMessage = 'Something went wrong, please try again';

    await handleApiRequest(
      fetchParams,
      setIsLoading,
      setNewAlert,
      successMessage,
      errorMessage,
      mutateReportContent
    );
  }

  return (
    <>
      <div className="report-content-page">
        {(errorColumnsForTableLoaded || columnsForTableData) &&
          reportContent &&
          temporaryData && (
            <>
              <ReportDetails
                isLoading={isLoading}
                data={temporaryData}
                columnsData={columnsForTableData}
                unselectedData={unselectedAlternatives}
                filename={filename}
                country={country}
                fileStatus={fileStatus}
                isReportStatusUpdated={isApproveReportLoaded}
                onRejectReport={handleRejectReport}
                onApproveReport={handleApproveReport}
                onAlternativeChoose={handleAlternativeChoose}
                onSetDuplicate={setDuplicate}
                onSetRememberChoise={setRememberChoise}
                onDeleteRow={setDeleteRowIndex}
              />

              <ConfirmationSnackbar
                isOpen={!!duplicate}
                message={`Do you want to apply changes for ${duplicate?.numbersOfDuplicates} similar cases in this report?`}
                onClose={() => setDuplicate(null)}
                onApprove={handleDuplicatesApprove}
              />

              <ConfirmationSnackbar
                isOpen={duplicate === null && rememberChoise}
                message={`Save your choice for the next report?`}
                onClose={() => setRememberChoise(null)}
                onApprove={handleRememberChoise}
              />

              <DeleteItemDialog
                opened={!!deleteRowIndex}
                onCancel={() => setDeleteRowIndex(null)}
                onDelete={confirmDeleteRow}
              />
            </>
          )}
        {(isColumnsForTableLoaded ||
          isApproveReportLoaded ||
          isLoadingReportsData ||
          isReportContentLoading) && (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        )}
      </div>
    </>
  );
};

export default ReportDetailsPage;
