import './Notification.scss';
import React from 'react';
import NotificationPeriods from 'components/NotificationSettings/NotificationPeriods/NotificationPeriods';
import NotificationRules from 'components/NotificationSettings/NotificationsRules/NotificationRules';
import Button from '@mui/material/Button';
import ToggleComponent from '../../customized-mui-elements/ToggleComponent/ToggleComponent';

type Period = 'Daily' | 'Weekly' | 'Monthly' | 'Quarterly' | 'Custom';
const notificationPeriods: Array<Period> = [
  //  "Daily",
  //  "Weekly",
  'Monthly',
  'Quarterly',
  //  "Custom",
];

const NotificationComponent: React.FC<any> = ({
  onSave,
  timelinesState,
  updateState,
  isDefaultToggleOn,
  isInEditMode,
  onDefaultToggleChange,
  onCancelDataEdit,
  isLoading,
}: any): JSX.Element => {
  return (
    <>
      {true && (
        <>
          <NotificationPeriods
            selectedPeriod={timelinesState.selectedPeriod}
            notificationPeriods={notificationPeriods}
            weeklyPeriod={timelinesState.weeklyPeriod}
            monthlyPeriod={timelinesState.monthlyPeriod}
            quarterlyPeriod={timelinesState.quarterlyPeriod}
            customPeriod={timelinesState.customPeriods}
            onPeriodChange={updateState.handlePeriodUpdate}
            onWeeklyPeriod={updateState.handleWeeklyPeriodUpdate}
            onMonthlyPeriod={updateState.handleMonthlyPeriodUpdate}
            onQuarterlyPeriod={updateState.handleQuarterlyPeriodUpdate}
            onCustomPeriod={updateState.handleCustomPeriodUpdate}
          />

          <NotificationRules
            selectedPeriod={timelinesState.selectedPeriod}
            dailyNotifications={timelinesState.dailyNotifications}
            weeklyNotifications={timelinesState.weeklyNotifications}
            monthlyNotifications={timelinesState.monthlyNotifications}
            quarterlyNotifications={timelinesState.quarterlyNotifications}
            customNotifications={timelinesState.customNotifications}
            selectedDaysState={timelinesState.selectedDaysDueDate}
            selectedFrequency={timelinesState.selectedFrequencyDueDate}
            onDailyNotificationsUpdate={
              updateState.handleDailyNotificationsUpdate
            }
            onWeeklyNotificationsUpdate={
              updateState.handleWeeklyNotificationsUpdate
            }
            onMonthlyNotifications={
              updateState.handleMonthlyNotificationsUpdate
            }
            onQuarterlyNotifications={
              updateState.handleQuarterlyNotificationsUpdate
            }
            onCustomNotifications={updateState.handleCustomNotificationsUpdate}
            onSelectedDaysState={updateState.handleSelectedDaysDueDateUpdate}
            onFrequencyChange={(e: any) =>
              updateState.handleSelectedFrequencyDueDateUpdate(e.target.value)
            }
          />

          <div className="notification-control">
            <div className="notification-control__buttons">
              <Button
                variant="outlined"
                onClick={onCancelDataEdit}
                disabled={!isInEditMode || isLoading}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={onSave}
                disabled={!isInEditMode || isLoading}
              >
                Save
              </Button>
            </div>
            <div className="notification-control__switcher">
              <ToggleComponent
                checked={isDefaultToggleOn}
                label={'Default settings'}
                onChange={(e: any) => onDefaultToggleChange(e)}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NotificationComponent;
