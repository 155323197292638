import useSWR from 'swr';
import fetchData from '../../../utils/fetchData';

function useFetchReportsData(
  country: string | null,
  authResult: any,
  getLastReportingPeriodData = false
) {
  const url = getLastReportingPeriodData
    ? `${process.env.REACT_APP_API_PYTHON_API}/get_file_details_with_status`
    : `${process.env.REACT_APP_API_PYTHON_API}/get_file_details_with_status_all`;

  const params = getLastReportingPeriodData
    ? { country, widget_status: 'all' }
    : { country };

  return useSWR([authResult, 'GET', url, params], fetchData, {
    // revalidateOnFocus: false,
    // refreshInterval: 10000000
  });
}

export default useFetchReportsData;
