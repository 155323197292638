import React, { useState, useEffect, useTransition, useContext } from 'react';
import './OnboardingTimelines.scss';
import useReportingPeriodsData from 'fetch/fetch-hooks/timelines-hooks/useReportingPeriods';
import CircularProgress from '@mui/material/CircularProgress';
import useNotificationsState from 'hooks/notifications-hooks/useNotificationsState';
import getTimelineConfigurations from 'services/timelines/getTimelineConfigurations';
import extractUpdateForTimelines from 'utils/extractUpdateForTimelines';
import updateObj from 'utils/updateObj';
import formatDataForBackEnd from 'utils/formatDataForBackEnd';
import { AlertsContext } from 'contexts/AlertsContext';
import OnboardingTimelinesNotification from 'components/OnboardingTimelinesNotification/OnboardingTimelinesNotification';
import TimelinesOnboardingFooter from 'components/TimelinesOnboardingFooter/TimelinesOnboardingFooter';
import { Box, Typography } from '@mui/material';
import { useOnboarding } from 'contexts/OnboardingContexts/OnboardingCountriesContext';
import { useStepper } from 'contexts/OnboardingContexts/StepperContext';
import { TimelinesDataType } from 'types/notificationsTypes';
import scrollToTop from 'utils/scrollToTop';
import { analogyDictionary } from 'constants/timelinesConstants';
import useEnabledReports from 'fetch/fetch-hooks/useEnabledReports';
import { UserDataContext } from 'App';
import { sendRequest } from 'fetch/fetch-requests/handleApiRequest';

type EnabledReportsType = {
  inventory: boolean;
  sellout: boolean;
};

const OnboardingTimelines: React.FC = (): JSX.Element => {
  const { selectedCountry, setLoading, loading } = useOnboarding();
  const { hasUnsavedChanges, setHasUnsavedChanges } = useStepper();
  const { setNewAlert } = useContext(AlertsContext);
  const [reportType, setReportType] = useState('inventory');
  const chosenCountry = selectedCountry?.country || '';
  const [isDefaultReport, setDefaultReportStatus] = useState(false);
  const { authResult } = useContext(UserDataContext);
  const {
    data: enabledReports,
    isLoading: isEnabledReportsLoading,
  }: { data: EnabledReportsType; isLoading: boolean } = useEnabledReports(
    authResult,
    chosenCountry
  );
  const {
    data: timelinesData,
    isLoading,
    mutate,
  } = useReportingPeriodsData(
    chosenCountry,
    reportType,
    isDefaultReport,
    authResult
  );
  const notificationPeriodsData: TimelinesDataType = timelinesData?.data
    ? timelinesData?.data[0]
    : undefined;
  const [formatedNotificationData, setFormatedNotificationData] =
    useState<any>();
  const [updateStatus, setUpdateStatus] = useState(false);
  const [_, startTransition] = useTransition();
  const [isDefaultToggleOn, setDefaultToggleOn] = useState(false);
  const { timelinesState, updateState, getSavedData, setTimelinesState } =
    useNotificationsState(
      handleTurnOnEditMode,
      chosenCountry,
      notificationPeriodsData?.reporting_frequency
    );

  useEffect(() => {
    if (enabledReports) {
      const { inventory, sellout } = enabledReports;
      if (!inventory && sellout) {
        handleReportTypeSwitch('sellout');
      } else if (!sellout && inventory) {
        handleReportTypeSwitch('inventory');
      } else {
        handleReportTypeSwitch('inventory');
      }
    }
  }, [enabledReports]);

  useEffect(() => {
    return () => {
      setHasUnsavedChanges(false);
    };
  }, []);

  /*added for correct refresh data*/

  useEffect(() => {
    if (notificationPeriodsData) {
      const hendledData = getTimelineConfigurations(notificationPeriodsData);
      const selectedPeriod = notificationPeriodsData?.reporting_frequency;
      if (hendledData) {
        console.log(hendledData);
        setFormatedNotificationData(hendledData);
      }
      if (selectedPeriod) {
        updateState.selectPeriod(selectedPeriod);
      }
    }
  }, [notificationPeriodsData]);

  useEffect(() => {
    if (notificationPeriodsData && isDefaultToggleOn) {
      const hendledData = getTimelineConfigurations(notificationPeriodsData);
      const selectedPeriod = notificationPeriodsData?.reporting_frequency;
      if (hendledData) {
        setFormatedNotificationData(hendledData);
      }
      if (selectedPeriod) {
        updateState.selectPeriod(selectedPeriod);
      }
    }
  }, [notificationPeriodsData, isDefaultToggleOn]);

  /*added for correct refresh data*/

  useEffect(() => {
    if (formatedNotificationData) {
      setTimelinesState(formatedNotificationData);
    }
  }, [formatedNotificationData]);

  function handleTurnOnEditMode() {
    // for any changes, the default switch must be deactivated
    setHasUnsavedChanges(true);
    setDefaultToggleOn(false);
  }

  function handleDefaultToggleChange(value: boolean) {
    if (value) {
      setDefaultToggleOn(true);
      setDefaultReportStatus(true);
      setHasUnsavedChanges(true);

      if (formatedNotificationData) {
        setTimelinesState(formatedNotificationData);
        updateState.selectPeriod(notificationPeriodsData?.reporting_frequency);
      }
    } else {
      setDefaultToggleOn(false);
      setDefaultReportStatus(false);
    }
  }

  function handleReportTypeSwitch(reportType: 'inventory' | 'sellout') {
    startTransition(() => {
      setReportType(reportType);
    });
  }

  const onDataFetchSuccess = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await mutate();
    scrollToTop();
    setUpdateStatus(false);
    setNewAlert({
      alertType: 'success',
      text: 'The update was completed successfully',
    });
    setDefaultReportStatus(false);
    setHasUnsavedChanges(false);
    setLoading(false);
    return true;
  };

  const onDataFetchFailed = () => {
    scrollToTop();
    setUpdateStatus(false);
    setNewAlert({
      alertType: 'error',
      text: 'Some issue happened',
    });
    setDefaultReportStatus(false);
    setHasUnsavedChanges(false);
    setLoading(false);
    return false;
  };

  async function handleSaveUpdates() {
    setLoading(true);
    const savedData = getSavedData();
    const formattedDataForBackEnd = formatDataForBackEnd(
      notificationPeriodsData
    );
    const updatedData = extractUpdateForTimelines(savedData, analogyDictionary);
    const dataForSave = updateObj(formattedDataForBackEnd, updatedData);
    setUpdateStatus(true);

    if (dataForSave.country && dataForSave) {
      dataForSave.country_old = dataForSave.country;
    }

    const fetchParams = {
      authResult: authResult,
      method: 'POST' as const,
      url: `${process.env.REACT_APP_API_PYTHON_API}/update_reporting_periods`,
      data: { data: [dataForSave] },
    };

    return await sendRequest(
      fetchParams,
      setUpdateStatus,
      onDataFetchFailed,
      onDataFetchSuccess
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography
        sx={{
          textAlign: 'left',
          padding: '16px 24px',
          fontFamily: 'Helvetica Neue',
          color: '#10384F',
        }}
        variant="h6"
      >
        Timelines
      </Typography>
      {!!enabledReports && (
        <div className="onboarding-notification-page">
          <div className="onboarding-table-content">
            <div className="notification-switcher">
              {enabledReports?.inventory && (
                <div
                  className={reportType === 'inventory' ? 'selected' : ''}
                  onClick={() => handleReportTypeSwitch('inventory')}
                >
                  Inventory report
                </div>
              )}
              {enabledReports?.sellout && (
                <div
                  className={reportType === 'sellout' ? 'selected' : ''}
                  onClick={() => handleReportTypeSwitch('sellout')}
                >
                  Sell-out report
                </div>
              )}
            </div>
            {reportType === 'inventory' &&
              formatedNotificationData &&
              timelinesState && (
                <OnboardingTimelinesNotification
                  timelinesState={timelinesState}
                  updateState={updateState}
                />
              )}

            {reportType === 'sellout' &&
              formatedNotificationData &&
              timelinesState && (
                <OnboardingTimelinesNotification
                  timelinesState={timelinesState}
                  updateState={updateState}
                />
              )}

            {(updateStatus ||
              isLoading ||
              loading ||
              isEnabledReportsLoading) && (
              <CircularProgress
                sx={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            )}
          </div>

          <TimelinesOnboardingFooter
              isDefaultToggleOn={isDefaultToggleOn}
              onDefaultToggleChange={handleDefaultToggleChange}
              saveBtnHandler={handleSaveUpdates}
              isNextBtnDisabled={hasUnsavedChanges}
            />
        </div>
      )}
    </Box>
  );
};

export default OnboardingTimelines;
