import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Button } from '@mui/material';

type TemplatesCustomExportButtonProps = {
  handleDownloadClick: () => void;
};

function TemplatesCustomExportButton({
  handleDownloadClick,
}: TemplatesCustomExportButtonProps) {
  return (
    <div style={{ display: 'inline' }}>
      <Button
        startIcon={<ArrowUpwardIcon />}
        onClick={() => {
          handleDownloadClick();
        }}
      >
        Export
      </Button>
    </div>
  );
}

export default TemplatesCustomExportButton;
