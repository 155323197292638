import { Snackbar, Button, IconButton } from '@mui/material';
import CrossIcon from 'icons/cross/_Icon_.svg';

const ConfirmationSnackbar = ({ isOpen, onClose, onApprove, message }: any) => {
  return (
    <>
      {isOpen && (
        <Snackbar
          open={isOpen}
          onClose={onClose}
          message={message}
          action={
            <>
              <Button color="primary" sx={
                {
                 fontSize: '13px',
                 color: 'rgba(0, 145, 223, 1)',
                 fontWeight: '500' 
                }
              } size="small" onClick={() => onApprove()}>
                YES
              </Button>
              <IconButton onClick={() => onClose()}>
                <img src={CrossIcon} alt="cross" />
              </IconButton>
            </>
          }
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={{
            '& .MuiSnackbarContent-root': {
              backgroundColor: 'rgba(50, 50, 50, 1)',
              color: 'rgba(255, 255, 255, 1)',
              fontFamily: 'Helvetica Neue'
            },
          }}
        />
      )}
    </>
  );
};

export default ConfirmationSnackbar;
