import defaultFetchRequest from 'fetch/fetch-requests/defaultFetch';

export interface FetchParams {
  authResult: {
    accessToken: string;
  };
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  url: string;
  data?: Record<string, any>;
}

export const handleApiRequest = async (
  fetchParams: FetchParams,
  setUpdateLoaded: (value: boolean) => void,
  setNewAlert: (alert: any) => void,
  successMessage: string,
  errorMessage: string,
  onDataFetchSuccess?: any
) => {
  setUpdateLoaded(true);
  try {
    const response: any = await defaultFetchRequest(fetchParams);
    if (response.ok) {
      // added delay until back-end will be fixed
      setTimeout(async () => {
        await onDataFetchSuccess();
        setUpdateLoaded(false);
        setNewAlert({ alertType: 'success', text: successMessage });
      }, 3500);
    } else {
      setUpdateLoaded(false);
      setNewAlert({ alertType: 'error', text: errorMessage });
    }
  } catch (error) {
    setUpdateLoaded(false);
    setNewAlert({ alertType: 'error', text: errorMessage });
  }
};

export const handleApiRequestUpdated = async (
  fetchParams: FetchParams,
  setUpdateLoaded: (value: boolean) => void,
  setNewAlert: (alert: any) => void,
  successMessage: string,
  errorMessage: string,
  onDataFetchSuccess?: any,
  onErrorFetch?: any
) => {
  setUpdateLoaded(true);
  try {
    const response: any = await defaultFetchRequest(fetchParams);
    const data = await response.json(); // Parse the JSON response
    if (response.ok) {
      // added delay until back-end will be fixed
      setTimeout(async () => {
        await onDataFetchSuccess();
        setUpdateLoaded(false);
        setNewAlert({ alertType: 'success', text: successMessage });
      }, 3500);
    } else {
      setUpdateLoaded(false);
      onErrorFetch(data);
    }
  } catch (error) {
    setUpdateLoaded(false);
    // setNewAlert({ alertType: 'error', text: errorMessage });
  }
};

export const sendRequest = async (
  fetchParams: FetchParams,
  setUpdateLoaded: (value: boolean) => void,
  onDataFetchFailed?: any,
  onDataFetchSuccess?: any
) => {
  setUpdateLoaded(true);
  try {
    const response: any = await defaultFetchRequest(fetchParams);
    if (response.ok) {
      onDataFetchSuccess();
      return true;
    } else {
      onDataFetchFailed();
      return false;
    }
  } catch (error) {
    setUpdateLoaded(false);
    return false;
  }
};

export const sendDownloadRequest = async (
  fetchParams: FetchParams,
  onDownloadSuccess: () => void,
  onDownloadFail: () => void
) => {
  try {
    const response = await defaultFetchRequest(fetchParams);

    if (!response) {
      onDownloadFail();
      throw new Error('No response from server');
    }

    if (response.ok) {
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;

        const contentDisposition = response.headers.get('content-disposition');
        const filename = contentDisposition
          ? contentDisposition.split('filename=')[1].replace(/['"]/g, '')
          : 'report.xlsx';

        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

        onDownloadSuccess();
      });
    } else {
      onDownloadFail();
    }
  } catch (error) {
    onDownloadFail();
  }
};
