import styled from '@emotion/styled';
import { Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
interface CustomTooltipProps extends TooltipProps {
  width?: string;
}

export const CustomTooltipWrapper = styled(
  ({ className, width = '400px', ...props }: CustomTooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(({ width }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: `0px 3px 5px -1px #00000033, 
                  0px 6px 10px 0px #00000024, 
                  0px 1px 18px 0px #0000001F`,
    minWidth: width,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
  },
}));
