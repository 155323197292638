import { ToggleButton, ToggleButtonGroup } from '@mui/material';

type CustomToggleButtonGroupProps<T extends string> = {
  value: T;
  options: Record<T, string>;
  onChange: (newValue: T) => void;
  disabled?: boolean;
};

const toggleBtnStyles = {
  fontSize: 12,
  fontFamily: 'Helvetica Neue',
  fontWeight: 400,
  padding: '4px 8px',
  textTransform: 'none',
  color: 'black',
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: 'var(--grey-600)',
    '&:hover': {
      color: '#fff',
      backgroundColor: 'var(--grey-600)',
    },
  },
  '&:hover': {
    backgroundColor: 'var(--grey-300)',
  },
};

function CustomToggleButtonGroup<T extends string>({
  value,
  options,
  onChange,
  disabled = false,
}: CustomToggleButtonGroupProps<T>) {
  return (
    <ToggleButtonGroup
      value={value}
      onChange={(event, newValue: T | null) => {
        if (newValue !== null) {
          onChange(newValue);
        }
      }}
      exclusive
      disabled={disabled}
      sx={{
        height: '20px',
      }}
    >
      {Object.keys(options).map((key) => {
        const optionValue = key as T;
        const label = options[optionValue];
        return (
          <ToggleButton
            key={optionValue}
            value={optionValue}
            sx={toggleBtnStyles}
          >
            {label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

export default CustomToggleButtonGroup;
