import React from 'react';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import './ReportDetails.scss';
import MappingAlternativesCell from 'components/MappingAlternatives/MappingAlternativesCell';
import {
  getNumbersOfDuplicates,
  getUnicProductKey,
  getColumnsForReport,
  createAlternativesColumn,
} from './services';
import {
  ReportStatusType,
  ReportDetailsData,
  ProductDetailsType,
  ReportDetailsProps,
  SelectedOptionType,
} from 'types/reportDetailsTypes';
import CustomToolbar from './CustomToolbar';
import CustomDatagridPagination from 'components/CustomDatagridPagination/CustomDatagridPagination';

const ReportDetails: React.FC<ReportDetailsProps> = ({
  isLoading,
  columnsData,
  data,
  unselectedData,
  filename,
  fileStatus,
  onRejectReport,
  onApproveReport,
  isReportStatusUpdated,
  onAlternativeChoose,
  country,
  onSetRememberChoise,
  onSetDuplicate,
  onDeleteRow,
}): JSX.Element => {
  function handleAlternativeChoose(
    productData: SelectedOptionType,
    fromSmartSearch: boolean
  ) {
    onAlternativeChoose(productData, fromSmartSearch);
    const { initial_product_data, uom }: ProductDetailsType =
      productData?.params;

    const numbersOfDuplicates = getNumbersOfDuplicates(
      unselectedData,
      initial_product_data.material_number,
      initial_product_data.product_name,
      uom
    );

    if (numbersOfDuplicates > 0) {
      onSetDuplicate({
        unicKey: getUnicProductKey(
          initial_product_data.product_name,
          initial_product_data.material_number,
          uom
        ),
        matchedMaterialNumber: productData.value,
        matchedMaterialName: productData.name,
        numbersOfDuplicates,
      });
    }

    console.log(productData, 'productData')

    onSetRememberChoise({
      matchedMaterialNumber: productData.value,
      matchedMaterialName: productData.name,
      params: productData.params
    });
  }

  const columnsForDataMappingReport: any[] = [
    ...getColumnsForReport(columnsData),
    createAlternativesColumn((params: any) => {
      return (
        <MappingAlternativesCell
          params={params.row}
          onAlternativeChoose={handleAlternativeChoose}
          country={country}
          onDeleteBtnClick={(index: number) => onDeleteRow(index)}
        />
      );
    }),
  ];

  const columnsForReport: any[] = [
    {
      field: '#',
      headerName: '#',
      filterable: false,
      valueFormatter: (_: any, params: any) => params.id + 1,
      renderCell: (params: GridRenderCellParams) =>
        params.api.getAllRowIds().indexOf(params.id) + 1,
      flex: 0.1,
    },

    {
      field: 'material_number',
      headerName: 'Material Number',
      flex: 0.5,
    },
    {
      field: 'product_name',
      headerName: 'Item Name',
      flex: 1,
    },
    {
      field: 'uom',
      headerName: 'UOM',
      flex: 0.3,
    },
    {
      field: 'volume',
      headerName: 'Volume',
      flex: 0.3,
    },
  ];

  const suitableColumns = (fileStatus: ReportStatusType) => {
    if (fileStatus === 'REVIEW') {
      return [...columnsForDataMappingReport];
    } else return [...columnsForReport];
  };

  const disableApproveReportButton = (data: Array<ReportDetailsData>) => {
    if (!data || !data.length) return true;
    return data.some((item) => item.matched === null);
  };

  return (
    <>
      <div style={{ height: 'calc(100vh - 132px)', width: '100%' }}>
        <DataGridPro
          disableRowSelectionOnClick
          loading={isLoading}
          hideFooterSelectedRowCount
          sx={{
            background: 'white',
            fontFamily: 'Helvetica Neue',
            color: '#10384F',
            '& .MuiDataGrid-columnHeader, & .MuiDataGrid-filler, & .MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--header.MuiDataGrid-scrollbarFiller--pinnedRight ':
              {
                backgroundColor: 'rgba(245, 245, 245, 1)',
              },
          }}
          columns={suitableColumns(fileStatus)}
          rows={data}
          rowHeight={52}
          pagination
          slots={{
            toolbar: CustomToolbar,
            pagination: (props) => (
              <CustomDatagridPagination
                {...props}
                pageSizes={[15, 25, 50, 75, 100]}
              />
            ),
          }}
          slotProps={{
            toolbar: {
              onApproveReport: onApproveReport,
              onRejectReport: onRejectReport,
              fileStatus: fileStatus,
              isReportStatusUpdated: isReportStatusUpdated,
              isApproveDisabled: disableApproveReportButton(data),
              fileName: filename,
            },
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 15 } },
            pinnedColumns: { right: ['alternatives'] },
          }}
          pageSizeOptions={[15, 25, 50, 75, 100]}
        />
      </div>
    </>
  );
};

export default ReportDetails;
