import { useState, useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { DataGridPro, GridRowOrderChangeParams } from '@mui/x-data-grid-pro';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PriviewModal from 'components/ReportTemplateTable/TemplatePriviewModal/TemplatePriviewModal';
import BasicPopover from 'components/BasicPopover/BasicPopover';
import EditModal from 'components/ReportTemplateTable/TemplateModals/EditModal';
import AddRowModal from 'components/ReportTemplateTable/TemplateModals/AddRowModal';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import './ReportTemplateTable.scss';
import { getFromLocalStorage } from 'services/storageInterection';
import { Switch } from '@mui/material';
import { UserDataContext } from '../../App';
import { ReportTemplateDataType } from 'types/reportTemplates';
import { columnsDataTypesDictionary } from 'constants/reportTemplatesConstants';
import ReportTemplateTableToolbar from 'components/ReportTemplateTable/ReportTempleteTableToolbar';
import CustomDatagridPagination from 'components/CustomDatagridPagination/CustomDatagridPagination';
import { backEndColumndDataType } from 'types/reportTemplates';
import { sendDownloadRequest } from 'fetch/fetch-requests/handleApiRequest';
import { AlertsContext } from 'contexts/AlertsContext';

//utils
function formatUpdatedDataForBackEnd(oldValue: any, newValue: any) {
  const result: any = { ...newValue };
  for (let key in oldValue) {
    result[`${key}_old`] = oldValue[key];
  }
  return result;
}
// utils

const DeleteDataFieldDialog = ({ open, onClose, onDelete }: any) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Data Field</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete this Data Field? You'll have to
          recreate it from scratch if you need it in the future.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={onDelete} variant="contained" color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ActionCellTemplates = ({ data, onActionClick }: any) => {
  return (
    <div className="action-cell">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={'long-menu'}
        aria-expanded={'true'}
        aria-haspopup="true"
        onClick={(e: any) => onActionClick(e.currentTarget, data)}
      >
        <MoreVertIcon />
      </IconButton>
    </div>
  );
};

const ReportTemplateTable = ({
  data,
  onRowUpdate,
  onAddNewRow,
  onDelete,
  isTableDataLoaded,
  reportType,
  isDisabledMode,
  tableHeight = 'calc(100vh - 180px)',
  isOnboardingReportTemplate = false,
  country,
}: any) => {
  const selectedCountry = country || getFromLocalStorage('selectedCountry');
  const [cellPopUpcurrentTarget, setCellPopUpcurrentTarget] =
    useState<any>(null);
  const [rowData, setRowData] = useState<any>(null);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [isDeliteMode, setDeliteMode] = useState<boolean>(false);
  const [isAddNewRowMode, setAddNewRowMode] = useState<boolean>(false);
  const [isPreviewMode, setPreviewMode] = useState<boolean>(false);
  const [loadingDownloadRequest, setLoadingDownloadRequest] = useState(false);
  const { userProfile, authResult } = useContext(UserDataContext);
  const { setNewAlert } = useContext(AlertsContext);

  function handleActionClick(currentTarget: any, data: any) {
    setCellPopUpcurrentTarget(currentTarget);
    setRowData(data.row);
  }

  function handleOpenEditRowWindow() {
    setEditMode(true);
    setCellPopUpcurrentTarget(null);
  }

  function handleDeliteRowWindow() {
    setDeliteMode(true);
    setCellPopUpcurrentTarget(null);
  }

  function handleEditSave(updatedData: ReportTemplateDataType) {
    const columnData = { ...rowData, country: selectedCountry };
    const columnDataAfterUpdate = {
      ...rowData,
      ...updatedData,
      country: selectedCountry,
    };
    setEditMode(false);
    onRowUpdate([
      formatUpdatedDataForBackEnd(columnData, columnDataAfterUpdate),
    ]);
  }

  function handleToggleRow(
    originalData: ReportTemplateDataType,
    updatedData: any
  ) {
    const columnData = { ...originalData, country: selectedCountry };
    const columnDataAfterUpdate = {
      ...originalData,
      ...updatedData,
      country: selectedCountry,
    };

    setEditMode(false);
    onRowUpdate([
      formatUpdatedDataForBackEnd(columnData, columnDataAfterUpdate),
    ]);
  }

  async function handleRowOrderChange(params: GridRowOrderChangeParams) {
    const { targetIndex, oldIndex } = params;
    const substitutedRow = { ...data[targetIndex] };
    substitutedRow.row_order = oldIndex + 1;
    const substituteRow = { ...data[oldIndex] };
    substituteRow.row_order = targetIndex + 1;
    const x = formatUpdatedDataForBackEnd(
      { ...data[targetIndex], country: selectedCountry },
      { ...substitutedRow, country: selectedCountry }
    );
    const y = formatUpdatedDataForBackEnd(
      { ...data[oldIndex], country: selectedCountry },
      { ...substituteRow, country: selectedCountry }
    );

    onRowUpdate([x, y]);
  }

  const isToggleDisabled = (
    isEmeaUser: boolean,
    rowData: ReportTemplateDataType
  ) => {
    if (isEmeaUser) {
      return !rowData.switcher_enabled_emea;
    } else {
      return !rowData.switcher_enabled_country;
    }
  };

  const isRowDeletable = (rowData: ReportTemplateDataType) => {
    if (rowData?.isEDF) {
      return false;
    } else {
      return true;
    }
  };

  const isRowEditable = (
    isEmeaUser: boolean,
    rowData: ReportTemplateDataType
  ) => {
    if (rowData?.isEDF) {
      return isEmeaUser ? true : false;
    } else return true;
  };

  const columns: any = [
    {
      field: 'row_order',
      headerName: '#',
      filterable: false,
      flex: 0.3,
    },
    {
      field: 'column_name',
      headerName: 'Field Name in Local Language',
      flex: 2.4,
    },
    {
      field: 'translated_column_name',
      headerName: 'Field Name in English',
      flex: 2.2,
    },
    {
      field: 'data_type',
      headerName: 'Data Type',
      valueGetter: (value: backEndColumndDataType) => {
        return columnsDataTypesDictionary[value];
      },
      maxWidth: 112,
      flex: 0.9,
    },
    {
      field: 'min_value',
      headerName: 'Min Value',
      valueGetter: (value: any, row: ReportTemplateDataType) => {
        if (row.data_type === 'str' || row.data_type === 'date') {
          return '';
        } else {
          return value;
        }
      },
      maxWidth: 120,
      flex: 1,
    },
    {
      field: 'max_value',
      headerName: 'Max Value',
      maxWidth: 120,
      valueGetter: (value: any, row: ReportTemplateDataType) => {
        if (row.data_type === 'str' || row.data_type === 'date') {
          return '';
        } else {
          return value;
        }
      },
      flex: 1,
    },
    {
      field: 'is_duplicate_constraint',
      headerName: 'Duplicate Check',
      type: 'boolean',
      valueGetter: (_: any, row: ReportTemplateDataType) => {
        if (row?.is_duplicate_constraint) {
          return true;
        } else return false;
      },
      renderCell: (params: any) => (
        <Switch
          checked={params?.value}
          disabled={isToggleDisabled(userProfile.isEMEA, params?.row)}
          onChange={(event) =>
            handleToggleRow(params?.row, {
              is_duplicate_constraint: event.target.checked,
            })
          }
          size="small"
        />
      ),
      flex: 0.7,
    },
    {
      field: 'mandatory',
      headerName: 'Empty cells not allowed',
      type: 'boolean',
      valueGetter: (_: any, row: ReportTemplateDataType) => {
        if (row?.mandatory) {
          return true;
        } else return false;
      },
      renderCell: (params: any) => (
        <Switch
          checked={params?.value}
          disabled={isToggleDisabled(userProfile.isEMEA, params?.row)}
          onChange={(event) =>
            handleToggleRow(params?.row, { mandatory: event.target.checked })
          }
          size="small"
        />
      ),
      flex: 0.7,
    },
    {
      field: 'actions',
      headerName: ' ',
      flex: 0.4,
      filterable: false,
      renderCell: (params: any) => (
        <div>
          {isRowEditable(userProfile.isEMEA, params?.row) && (
            <ActionCellTemplates
              data={params}
              onActionClick={handleActionClick}
            />
          )}
        </div>
      ),
    },
  ];

  function handleAddNewColumn(data: any, clearInputsFn: () => void) {
    onAddNewRow(data, clearInputsFn);
    setAddNewRowMode(false);
  }

  function handleDownloadClick() {
    setLoadingDownloadRequest(true);
    const url = `${process.env.REACT_APP_API_PYTHON_API}/get_excel_report_templates`;

    const fetchParams = {
      authResult: authResult,
      method: 'GET' as const,
      url: url,
      data: {
        country: selectedCountry,
        report_type: reportType,
        report_subtype: 'default',
      },
    };

    const onFailure = () => {
      setNewAlert({
        alertType: 'error',
        text: `There was an issue with the attempt to download file`,
      });
      setLoadingDownloadRequest(false);
    };
    const onSuccess = () => {
      setLoadingDownloadRequest(false);
    };
    sendDownloadRequest(fetchParams, onSuccess, onFailure);
  }

  return (
    <div style={{ height: tableHeight, width: '100%', position: 'relative' }}>
      {isDisabledMode && <div className="disabled-table-screen"></div>}
      {isEditMode && (
        <EditModal
          isToggleDisabled={isToggleDisabled(userProfile.isEMEA, rowData)}
          isUserEMEA={userProfile.isEMEA}
          open={isEditMode}
          editedRow={rowData}
          onClose={() => {
            setRowData(null);
            setEditMode(false);
          }}
          allRowsData={data}
          onSave={handleEditSave}
        />
      )}

      <DeleteDataFieldDialog
        open={isDeliteMode}
        onClose={() => setDeliteMode(false)}
        onDelete={() => {
          onDelete({ ...rowData, country: selectedCountry });
          setDeliteMode(false);
        }}
      />

      <PriviewModal
        open={isPreviewMode}
        reportType={reportType}
        onClose={() => setPreviewMode(false)}
        data={data}
      />

      <AddRowModal
        allRowsData={data}
        open={isAddNewRowMode}
        onClose={() => setAddNewRowMode(false)}
        onSave={handleAddNewColumn}
        order={data.length + 1}
        reportType={reportType}
        country={selectedCountry}
      />

      <BasicPopover
        targetEvent={cellPopUpcurrentTarget}
        setTargetEvent={setCellPopUpcurrentTarget}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ width: '160px', height: '36px' }}
              onClick={handleOpenEditRowWindow}
            >
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          {isRowDeletable(rowData) && (
            <ListItem disablePadding>
              <ListItemButton
                sx={{ width: '160px', height: '36px' }}
                onClick={handleDeliteRowWindow}
              >
                <ListItemText primary="Delete" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </BasicPopover>

      <DataGridPro
        getRowId={(row) => row.translated_column_name}
        sx={{
          background: 'white',
          border: isOnboardingReportTemplate ? 'none' : '1px solid e0e0e0',
          borderTop: 'none',
          maxWidth: '100%',
          overflowY: 'scroll',
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          fontFamily: 'Helvetica Neue',
          color: '#10384F',
          '& .MuiDataGrid-columnHeader, .MuiDataGrid-scrollbarFiller': {
            backgroundColor: 'rgba(245, 245, 245, 1)',
          },
        }}
        rows={data}
        columns={columns}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        loading={isTableDataLoaded}
        slots={{
          toolbar: () => (
            <ReportTemplateTableToolbar
              onAddNewRow={() => setAddNewRowMode(true)}
              onPreview={() => setPreviewMode(true)}
              isDisabledMode={isDisabledMode}
              isOnboardingReportTemplate={isOnboardingReportTemplate}
              handleDownloadClick={handleDownloadClick}
            />
          ),
          pagination: (props) => (
            <CustomDatagridPagination
              {...props}
              pageSizes={[10, 25, 50, 100]}
            />
          ),
        }}
        pagination
        initialState={{
          pagination: { paginationModel: { pageSize: 25 } },
        }}
      />
    </div>
  );
};

export default ReportTemplateTable;
