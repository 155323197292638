import DistributorsTable from 'components/DistributorsTable/DistributorsTable';
import useDistributorsDetails from 'fetch/fetch-hooks/distributors-hooks/useDistributorsDetails';
import { getFromLocalStorage } from '../../services/storageInterection';
import { useMemo, useEffect, useContext, useState } from 'react';
import { PageInfoContext } from '../../contexts/PageInfoContext';
import OnboardingDistributorDetailsPage from 'pages/distributor-details-page/OnboardingDistributorDetailsPage';
import { Box, CircularProgress } from '@mui/material';
import {
  DistributorType,
  DistributorTypeWithIndex,
} from 'components/DistributorsTable/types';
import { UserDataContext } from 'App';

function getDistributorsRowData(
  data: DistributorType[]
): DistributorTypeWithIndex[] {
  return data.map((distributor, index) => ({
    idx: index + 1,
    ...distributor,
  }));
}

export default function DistributorsPage({
  country,
  tableHeight = 'calc(100vh - 132px)',
  isOnboardingDistributorsPage = false,
}: {
  country?: string;
  tableHeight?: string;
  isOnboardingDistributorsPage?: boolean;
}) {
  const { authResult } = useContext(UserDataContext);
  const [distributorToShowId, setDistributorToShowId] = useState<number | null>(
    null
  );
  const { setPageInfo } = useContext(PageInfoContext);

  const selectedCountry = country || getFromLocalStorage('selectedCountry');
  const { data, isLoading } = useDistributorsDetails(
    selectedCountry,
    authResult
  );

  const distributors: DistributorType[] = data?.data;

  useEffect(() => {
    if (!isOnboardingDistributorsPage) {
      setPageInfo({
        headerContent: 'Distributors',
      });
    }
  }, [isOnboardingDistributorsPage, setPageInfo]);

  const rowData = useMemo(() => {
    if (Array.isArray(distributors)) {
      return getDistributorsRowData(distributors);
    }
    return [];
  }, [distributors]);

  const distributorToShow = distributors?.find(
    (distributor) => distributor.distributor_id === distributorToShowId
  );

  const distributorToShowWithPhoneArray = distributorToShow && {
    ...distributorToShow,
    phone: distributorToShow.phone?.split(', ').filter(Boolean) || [],
  };

  function handleRowClick(id: number) {
    setDistributorToShowId(id);
  }

  const containerStyles = (isOnboarding: boolean) => ({
    background: 'white',
    border: isOnboarding ? 'none' : '1px solid #e0e0e0',
    borderRadius: isOnboarding ? '0px' : '4px',
    overflow: 'hidden',
  });

  if (distributorToShowWithPhoneArray) {
    return (
      <OnboardingDistributorDetailsPage
        setDistributorToShowId={setDistributorToShowId}
        distributor={distributorToShowWithPhoneArray}
      />
    );
  }
  return (
    <Box sx={containerStyles(isOnboardingDistributorsPage)}>
      {isLoading ? (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      ) : (
        <DistributorsTable
          handleRowClick={handleRowClick}
          country={selectedCountry}
          authResult={authResult}
          rowData={distributors?.length > 0 ? rowData : []}
          tableHeight={tableHeight}
        />
      )}
    </Box>
  );
}
