import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

type ChartContainerProps = {
  title: ReactNode;
  children: ReactNode;
  titleStyle?: React.CSSProperties;
  customStyles?: React.CSSProperties;
};

function ChartContainer({
  title,
  children,
  titleStyle,
  customStyles,
}: ChartContainerProps) {
  const customStylesObj = typeof customStyles === 'object' ? customStyles : {};

  const cardStyles = {
    height: '100%',
    p: '24px',
    boxShadow: '0px 4px 5px 0px #C2C2C233',
    bgcolor: '#fff',
    boxSizing: 'border-box',
    ...customStylesObj,
  };

  return (
    <>
      <Box sx={cardStyles}>
        <Typography
          color={'var(--grey-800)'}
          mb={'12px'}
          variant="h5"
          textAlign={'left'}
          fontFamily={'Helvetica Neue'}
          style={titleStyle}
        >
          {title}
        </Typography>
        {children}
      </Box>
    </>
  );
}

export default ChartContainer;
