import { DateTime } from 'luxon';
import AggregationPerProductChart from './AggregationPerProductChart';
import { getDailyStats, getStatArray, isInDateRange } from './chartUtils';
import { useContext, useMemo } from 'react';
import ChartContainer from '../shared/ChartContainer';
import useAggregationPerProduct from 'fetch/fetch-hooks/emea-dashboard-hooks/useAggregationPerProduct';
import { ProductData } from './types';
import { UserDataContext } from 'App';

function AggregationPerProduct({ country }: { country: string }) {
  const { authResult } = useContext(UserDataContext);
  const { data: aggregationPerProduct } = useAggregationPerProduct(
    country,
    authResult
  );
  const now = DateTime.now();

  const currentMonthStart = now.startOf('month');
  const startOfPastFourMonths = currentMonthStart.minus({ months: 4 });
  const endOfPastFourMonths = currentMonthStart.minus({ days: 1 });

  const dailyStats = useMemo(() => {
    const data =
      aggregationPerProduct?.data.filter((product: ProductData) =>
        isInDateRange(product.date, startOfPastFourMonths, endOfPastFourMonths)
      ) || [];

    return getDailyStats(data, startOfPastFourMonths, endOfPastFourMonths);
  }, [aggregationPerProduct, startOfPastFourMonths, endOfPastFourMonths]);

  return (
    <>
      <ChartContainer title="Aggregation per product">
        <AggregationPerProductChart
          statArray={getStatArray(dailyStats, startOfPastFourMonths)}
        />
      </ChartContainer>
    </>
  );
}

export default AggregationPerProduct;
