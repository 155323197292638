import { Box } from '@mui/material';
import AggregationPerProduct from './AggregationPerProduct/AggregationPerProduct';
import CountryReportingPerformance from './CountryReportingPerformance/CountryReportingPerformance';
import ReportingPerformance from './ReportingPerformance/ReportingPerformance';
import ReportSubmissionAttempts from './ReportSubmissionAttempts/ReportSubmissionAttempts';
import { getFromLocalStorage } from 'services/storageInterection';

function Dashboard2() {
  const country = getFromLocalStorage('selectedCountry');
  return (
    <Box
      sx={{
        display: 'grid',
        marginInline: 'auto',
        maxWidth: '80%',
        gridTemplateColumns: 'repeat(auto-fit, minmax(600px, 1fr))',
        gap: '30px',
      }}
    >
      <ReportingPerformance country={country} />
      <CountryReportingPerformance />
      <ReportSubmissionAttempts country={country} />
      <AggregationPerProduct country={country} />
    </Box>
  );
}

export default Dashboard2;
