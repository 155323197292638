import { Box } from '@mui/material';

export const tooltipContentStyles = {
  '& div': {
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Helvetica Neue',
    color: '#10384F',
    '& p': {
      fontSize: '10px',
      margin: 0,
      lineHeight: '14px',
      fontWeight: 500,
    },
    '& p:first-child': {
      fontSize: '12px',
      lineHeight: '20px',
      fontWeight: 400,
    },
  },
};

export const TemplatesModalsTooltipContent = () => {
  return (
    <Box sx={tooltipContentStyles}>
      <div>
        <p>Number Data Type</p>
        <p>Numeric values without decimal points, e.g. 375</p>
      </div>
      <div>
        <p>Decimal Data Type</p>
        <p>Numeric values with decimal points, e.g. 3.75</p>
      </div>
      <div>
        <p>Date Data Type</p>
        <p>Calendar dates in dd/mm/yyyy format, e.g. 13/01/2024</p>
      </div>
      <div>
        <p>Text Data Type</p>
        <p>
          Alphanumeric characters, including letters, numbers, and symbols, e.g.
          for Field 'Invoice Number' the value can be UA-24-17-030/0001-21​33
        </p>
      </div>
    </Box>
  );
};
