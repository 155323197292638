import React, { useEffect, useState } from 'react';
import { Popover, Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import {
  convertRole,
  convertBack,
  specializedSortRoles,
} from 'utils/roleConvertor';

function ProfilePopUp({
  userName,
  userRoles,
  selectedRole,
  onRoleChange,
  onLogout,
}: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentSelectedRole, setSelectedRole] = useState<any>();

  useEffect(() => {
    setSelectedRole(selectedRole);
  }, [selectedRole]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    // Sign-out logic, e.g., clearing auth tokens or calling an API
    console.log('Signed out');
    handleClose(); // Close the popover
    onLogout();
  };

  const open = Boolean(anchorEl);

  function handleRoleChange(value: any) {
    const convertedBackrole = convertBack(value.target.value);
    setSelectedRole(convertedBackrole);
    onRoleChange(convertedBackrole);
  }

  const convertedUserRoles = userRoles.map(convertRole);
  const sortedUserRoles = convertedUserRoles.sort(specializedSortRoles);

  return (
    <>
      <div onClick={handleOpen}>{userName}</div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            mt={2}
          >
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small-label">User role:</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={
                  !!currentSelectedRole && convertRole(currentSelectedRole)
                }
                label="User role"
                onChange={handleRoleChange}
              >
                {!!userRoles &&
                  sortedUserRoles.map((role: string) => (
                    <MenuItem value={role} key={role}>
                      {role}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <Button
              sx={{ marginLeft: '10px' }}
              variant="text"
              startIcon={<LogoutIcon />}
              onClick={handleSignOut}
            >
              Logout
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default ProfilePopUp;
