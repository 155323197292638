import React, { useState, useEffect, useTransition, useContext } from 'react';
import './TimelinesPage.scss';
import NotificationComponent from 'components/NotificationSettings/Notification';
import useReportingPeriodsData from 'fetch/fetch-hooks/timelines-hooks/useReportingPeriods';
import { getFromLocalStorage } from 'services/storageInterection';
import CircularProgress from '@mui/material/CircularProgress';
import useNotificationsState from 'hooks/notifications-hooks/useNotificationsState';
import { PageInfoContext } from 'contexts/PageInfoContext';
import getTimelineConfigurations from 'services/timelines/getTimelineConfigurations';
import extractUpdateForTimelines from 'utils/extractUpdateForTimelines';
import updateObj from 'utils/updateObj';
import formatDataForBackEnd from 'utils/formatDataForBackEnd';
import { AlertsContext } from 'contexts/AlertsContext';
import {
  TimelinesDataType,
  HandledSavedTimelinesSettingsType,
} from 'types/notificationsTypes';
import scrollToTop from 'utils/scrollToTop';
import { analogyDictionary } from 'constants/timelinesConstants';
import useEnabledReports from 'fetch/fetch-hooks/useEnabledReports';
import { UserDataContext } from 'App';
import { sendRequest } from 'fetch/fetch-requests/handleApiRequest';

type EnabledReportsType = {
  inventory: boolean;
  sellout: boolean;
};

const TimelinesPage: React.FC<any> = (): JSX.Element => {
  const { setPageInfo } = useContext(PageInfoContext);
  const { setNewAlert } = useContext(AlertsContext);
  const [reportType, setReportType] = useState('inventory');
  const selectedCountry = getFromLocalStorage('selectedCountry');
  const [isDefaultReport, setDefaultReportStatus] = useState(false);
  const { authResult } = useContext(UserDataContext);
  const {
    data: enabledReports,
    isLoading: isEnabledReportsLoading,
  }: { data: EnabledReportsType; isLoading: boolean } = useEnabledReports(
    authResult,
    selectedCountry
  );
  const {
    data: timelinesData,
    isLoading,
    mutate,
  } = useReportingPeriodsData(
    selectedCountry,
    reportType,
    isDefaultReport,
    authResult
  );
  const notificationPeriodsData: TimelinesDataType = timelinesData?.data
    ? timelinesData?.data[0]
    : undefined;

  const [formatedNotificationData, setFormatedNotificationData] =
    useState<any>();
  const [updateStatus, setUpdateStatus] = useState(false);
  const [_, startTransition] = useTransition();
  const [editStatus, setEditStatus] = useState(true);
  const [isDefaultToggleOn, setDefaultToggleOn] = useState(false);
  const [isInEditMode, setEditMode] = useState(false);
  const { timelinesState, updateState, getSavedData, setTimelinesState } =
    useNotificationsState(
      handleTurnOnEditMode,
      selectedCountry,
      notificationPeriodsData?.reporting_frequency
    );

  useEffect(() => {
    if (enabledReports) {
      const { inventory, sellout } = enabledReports;
      if (!inventory && sellout) {
        handleReportTypeSwitch('sellout');
      } else if (!sellout && inventory) {
        handleReportTypeSwitch('inventory');
      } else {
        handleReportTypeSwitch('inventory');
      }
    }
  }, [enabledReports]);

  useEffect(() => {
    setPageInfo({
      headerContent: 'Timelines',
    });
  }, []);

  /*added for correct refresh data*/

  useEffect(() => {
    if (notificationPeriodsData) {
      const hendledData = getTimelineConfigurations(notificationPeriodsData);
      const selectedPeriod = notificationPeriodsData?.reporting_frequency;
      if (hendledData) {
        console.log(hendledData);
        setFormatedNotificationData(hendledData);
      }
      if (selectedPeriod) {
        updateState.selectPeriod(selectedPeriod);
      }
    }
  }, [notificationPeriodsData]);

  useEffect(() => {
    if (notificationPeriodsData && isDefaultToggleOn) {
      const hendledData = getTimelineConfigurations(notificationPeriodsData);
      const selectedPeriod = notificationPeriodsData?.reporting_frequency;
      if (hendledData) {
        setFormatedNotificationData(hendledData);
      }
      if (selectedPeriod) {
        updateState.selectPeriod(selectedPeriod);
      }
    }
  }, [notificationPeriodsData, isDefaultToggleOn]);

  /*added for correct refresh data*/

  useEffect(() => {
    if (formatedNotificationData) {
      setTimelinesState(formatedNotificationData);
    }
  }, [formatedNotificationData]);

  function handleCancelDataEdit() {
    setDefaultToggleOn(false);
    setDefaultReportStatus(false);
    setEditMode(false);

    if (formatedNotificationData) {
      setTimelinesState(formatedNotificationData);
    }
  }

  function handleTurnOnEditMode() {
    // for any changes, the default switch must be deactivated
    setEditMode(true);
    setDefaultToggleOn(false);
  }

  function handleDefaultToggleChange(value: boolean) {
    if (value) {
      setDefaultToggleOn(true);
      setDefaultReportStatus(true);
      setEditMode(true);

      if (formatedNotificationData) {
        setTimelinesState(formatedNotificationData);
        updateState.selectPeriod(notificationPeriodsData?.reporting_frequency);
      }
    } else {
      setDefaultToggleOn(false);
      setDefaultReportStatus(false);
    }
  }

  function handleReportTypeSwitch(reportType: 'inventory' | 'sellout') {
    startTransition(() => {
      setReportType(reportType);
    });
  }

  const onDataFetchSuccess = async () => {
    // Temporary implementation with setTimeout; adjust as needed
    await new Promise((resolve) => setTimeout(resolve, 2000));
    await mutate();
    scrollToTop();
    setUpdateStatus(false);
    setNewAlert({
      alertType: 'success',
      text: 'The update was completed successfully',
    });
    setDefaultReportStatus(false);
    setEditMode(false);
    return true;
  };

  const onDataFetchFailed = () => {
    setNewAlert({
      alertType: 'error',
      text: 'Some issue happened',
    });
    scrollToTop();
    setUpdateStatus(false);
    setDefaultReportStatus(false);
    setEditMode(false);
    return false;
  };

  function handleSaveUpdates() {
    const savedData = getSavedData();
    console.log(savedData, 'savedData');
    const formatedDataForBackEnd = formatDataForBackEnd(
      notificationPeriodsData
    );
    const updatedData = extractUpdateForTimelines(savedData, analogyDictionary);
    const dataForSave: HandledSavedTimelinesSettingsType = updateObj(
      formatedDataForBackEnd,
      updatedData
    );
    setUpdateStatus(true);

    const fetchParams = {
      method: 'POST' as const,
      url: `${process.env.REACT_APP_API_PYTHON_API}/update_reporting_periods`,
      data: { data: [dataForSave] },
      authResult: authResult, // Assuming you have `authResult` accessible; make adjustments accordingly
    };

    return sendRequest(
      fetchParams,
      setUpdateStatus,
      () => onDataFetchFailed(),
      () => onDataFetchSuccess()
    );
  }

  return (
    <>
      <div className="notification-page test-01">
        {!!enabledReports && (
          <>
            <div className="notification-switcher">
              {enabledReports?.inventory && (
                <div
                  className={reportType === 'inventory' ? 'selected' : ''}
                  onClick={() => handleReportTypeSwitch('inventory')}
                >
                  Inventory report
                </div>
              )}
              {enabledReports?.sellout && (
                <div
                  className={reportType === 'sellout' ? 'selected' : ''}
                  onClick={() => handleReportTypeSwitch('sellout')}
                >
                  Sell-out report
                </div>
              )}
            </div>
            {reportType === 'inventory' && 
              formatedNotificationData &&
              timelinesState && (
                <NotificationComponent
                  data={formatedNotificationData}
                  onSave={handleSaveUpdates}
                  onDefault={setDefaultReportStatus}
                  isDefault={isDefaultReport}
                  editStatus={editStatus}
                  onEdit={setEditStatus}
                  timelinesState={timelinesState}
                  updateState={updateState}
                  isInEditMode={isInEditMode}
                  isDefaultToggleOn={isDefaultToggleOn}
                  onDefaultToggleChange={handleDefaultToggleChange}
                  onTurnOnEditMode={handleTurnOnEditMode}
                  onCancelDataEdit={handleCancelDataEdit}
                  isLoading={isLoading}
                />
              )}

            {reportType === 'sellout' &&
              formatedNotificationData &&
              timelinesState && (
                <NotificationComponent
                  data={formatedNotificationData}
                  onSave={handleSaveUpdates}
                  onDefault={setDefaultReportStatus}
                  isDefault={isDefaultReport}
                  editStatus={editStatus}
                  onEdit={setEditStatus}
                  timelinesState={timelinesState}
                  updateState={updateState}
                  isInEditMode={isInEditMode}
                  isDefaultToggleOn={isDefaultToggleOn}
                  onDefaultToggleChange={handleDefaultToggleChange}
                  onTurnOnEditMode={handleTurnOnEditMode}
                  onCancelDataEdit={handleCancelDataEdit}
                  isLoading={isLoading}
                />
              )}
          </>
        )}
        {updateStatus || isLoading || isEnabledReportsLoading ? (
          <CircularProgress
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ) : null}
      </div>
    </>
  );
};

export default TimelinesPage;
