import useSWR from 'swr';
import fetchData from 'utils/fetchData';

export default function useDistributorsDetails(
  selectedCountry: string,
  authResult: any,
  distributorId = 'all'
) {
  return useSWR(
    [
      authResult,
      'GET',
      `${process.env.REACT_APP_API_PYTHON_API}/get_distributors_details`,
      { country: selectedCountry, distributor_id: distributorId },
    ],
    fetchData
  );
}
