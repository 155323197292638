import {
  TableColumn,
} from 'types/reportDetailsTypes';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

export function getUnselectedAlternatives(data: any) {
  const groupedProductsObject: any = {};

  for (let obj of data) {
    const uniqueKey = `${obj.initial_product_data.product_name}/${obj.initial_product_data.material_number}/${obj.uom || ''}`;

    if (!obj.matched) {
      if (!groupedProductsObject.hasOwnProperty(uniqueKey)) {
        groupedProductsObject[uniqueKey] = { duplicates: [obj] };
      } else {
        groupedProductsObject[uniqueKey].duplicates.push(obj);
      }
    }
  }

  return groupedProductsObject;
}

export function getNumbersOfDuplicates(
  unselectedAlternatives: any,
  number: any,
  name: any,
  uom: any,
) {
  const uniqueKey = getUnicProductKey(name, number, uom);

  if (
    unselectedAlternatives[uniqueKey] &&
    unselectedAlternatives[uniqueKey].duplicates.length > 1
  ) {
    return unselectedAlternatives[uniqueKey].duplicates.length - 1;
  } else return 0;
}

export function getUnicProductKey(name: any, number: any, uom: any) {
  return `${name}/${number}/${uom || ''}`;
}

export function getColumnsForReport(columnsData: TableColumn[]) {
  if (columnsData) {
    const result = columnsData
      .sort((a: TableColumn, b: TableColumn) => a.row_order - b.row_order)
      .map((obj: TableColumn) => {
        if (obj.column_name === 'Material_number') {
          return {
            field: 'material_number',
            headerName: 'Material Number',
            width: 125,
            minWidth: 150,
            maxWidth: 200,

            valueGetter: (params: any, row: any) => {
              const materialNumber = row?.initial_product_data?.material_number;
              return materialNumber === 'null' ? ' ' : materialNumber || '';
            },
          };
        } else if (obj.column_name === 'UOM') {
          return {
            field: 'uom',
            headerName: obj.column_name,
            width: 45,
            minWidth: 5,
            maxWidth: 80,
          };
        } else if (
          obj.column_name === 'SellOut_Qty' ||
          obj.column_name === 'Stock_Qty'
        ) {
          return {
            field: 'volume',
            headerName: `Volume`,
            width: 90,
            minWidth: 50,
            maxWidth: 180,
          };
        } else if (obj.column_name === 'Item Description') {
          return {
            field: 'product_name',
            headerName: obj.column_name,
            width: 260,
            minWidth: 50,
            maxWidth: 450,
            valueGetter: (params: any, row: any) => {
              return row?.initial_product_data?.product_name || ''; // Default to empty string if undefined
            },
          };
        } else {
          return {
            field: obj.column_name,
            headerName: obj.column_name,
            width: 125,
            minWidth: 150,
            maxWidth: 200,
          };
        }
      });

    return [
      {
        field: '#',
        headerName: '#',
        filterable: false,
        valueFormatter: (_: any, params: any) => params.id + 1,
        renderCell: (params: GridRenderCellParams) =>
          params.api.getAllRowIds().indexOf(params.id) + 1,
        width: 80,
        minWidth: 5,
      },
      ...result,
    ];
  } else
    return [
      {
        field: '#',
        headerName: '#',
        filterable: false,
        valueFormatter: (_: any, params: any) => params.id + 1,
        renderCell: (params: GridRenderCellParams) =>
          params.api.getAllRowIds().indexOf(params.id) + 1,
        flex: 0.1,
      },

      {
        field: 'initial_product_data',
        headerName: 'Material Number',
        flex: 0.5,
        valueGetter: (params: any, row: any) => {
          // Access the nested property safely
          const materialNumber = row?.initial_product_data?.material_number;
          // Check if it's "null" (string) and handle it
          return materialNumber === 'null' ? ' ' : materialNumber || '';
        },
      },
      {
        field: 'initial_product_datas',
        headerName: 'Item Name',
        flex: 1,
        valueGetter: (params: any, row: any) => {
          return row?.initial_product_data?.product_name || ''; // Default to empty string if undefined
        },
      },
      {
        field: 'uom',
        headerName: 'UOM',
        flex: 0.3,
      },
      {
        field: 'volume',
        headerName: 'Volume',
        flex: 0.3,
      },
    ];
}

export function createAlternativesColumn(MappingAlternativesCellFn: any) {
  return {
    field: 'alternatives',
    headerName: 'Bayer Mapping',
    disableExport: true,
    valueGetter: (params: any, row: any) => row,
    renderCell: MappingAlternativesCellFn,

    filterOperators: [
      {
        label: 'All',
        value: 'all',
        getApplyFilterFn: (filterItem: any) => {
          return (params: any) => {
            if (params) {
              return params;
            }
          };
        },
      },
      {
        label: 'Alternatives found',
        value: 'alternativesFound',
        getApplyFilterFn: (filterItem: any) => {
          return (params: any) => {
            if (!params?.matched && params?.alternatives.length > 0) {
              return params;
            }
          };
        },
      },
      {
        label: 'Mapped',
        value: 'mapped',
        getApplyFilterFn: (filterItem: any) => {
          return (params: any) => {
            if (params?.matched) {
              return params;
            }
          };
        },
      },
      {
        label: 'Not mapped',
        value: 'notMapped',
        getApplyFilterFn: (filterItem: any) => {
          return (params: any) => {
            if (!params?.matched && !params?.alternatives.length) {
              return params;
            }
          };
        },
      },
    ],
    width: 465,
    minWidth: 350,
    maxWidth: 550,
  };
}
