import useSWR from 'swr';
import fetchData from 'utils/fetchData';

export type EnabledReportsType = {
  inventory: boolean;
  sellout: boolean;
};

function useEnabledReports(authResult: any, country: string | any) {
  return useSWR(
    [
      authResult,
      'GET',
      `${process.env.REACT_APP_API_PYTHON_API}/enabled_report_types?country=${country}`,
    ],
    fetchData,
    {
      // revalidateOnFocus: false,
      // refreshInterval: 10000000
    }
  );
}

export default useEnabledReports;
