import { Box } from '@mui/material';

const tooltipContentStyles = {
  width: '200px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  fontFamily: 'Helvetica Neue',
  fontSize: '10px',
  fontWeight: 500,
  lineHeight: '14px',
  color: '#10384f',
  '& p': {
    margin: 0,
  },
};

function TemplatesTableModalContent() {
  return (
    <Box sx={tooltipContentStyles}>
      <div>
        <p>Example for September 2023 for the Distributor with ID=12345678:</p>
      </div>
      <div>
        <p>InventoryReport_12345678_09_2023.csv</p>
        <p>SellOutReport_12345678_09_2023.csv</p>
      </div>
    </Box>
  );
}

export default TemplatesTableModalContent;
